// Here you can add other styles
img {
  width: 100%;
}

.rule-heading {
  text-align: center;
}

#rule-app {
  text-align: center;
  margin-top: 10px;
}

// #rule-app .app-row {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 10px;
// }

// #rule-app .app-row .app-col {
//   width: 25%;
// }

// #rule-app .app-row .app-col select,
// #rule-app .app-row .app-col input {
//   padding: 5px;
//   min-width: 120px;
// }

.text-red {
  color: #e55353;
}

.drop-target {
  min-height: 10px;
}

.qr-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}